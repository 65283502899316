@use "sass:map";
@use "sass:list";
@use "sass:math";
@use "sass:string";

@import "../../resources/styles/easing";

$mobile-contents-width: 720px;

$one-px: math.div(1px, $mobile-contents-width) * 100;

$digit-chars-map: (
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
);

@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    @error "Expected a string, but got #{$value}.";
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';

  @for $i from if($minus, 2, 1) through str-length($value) {
    $char: str-slice($value, $i, $i);

    @if not (index(map-keys($digit-chars-map), $char) or $char == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i))
    }

    @if $char == '.' {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($digit-chars-map, $char);
    } @else {
      $digits: $digits * 10;
      $result: $result + math.div(map-get($digit-chars-map, $char), $digits);
    }
  }

  @return if($minus, -$result, $result);
}

@function to-length($value, $unit) {
  $units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);

  @if not index(map-keys($units), $unit) {
    @error "Unknown unit #{$unit}.";
  }

  @return $value * map-get($units, $unit);
}

@function is-digit($str) {
  @if type-of($str) == "string" {
    @return map.get($digit-chars-map, $str) != null;
  } @else {
    @return false;
  }
}

@function tokenization($str) {
  @if type-of($str) == "string" {
    $tokens: ();
    $cursor: 0;

    @for $i from 1 through string.length($str) {
      $curr: string.slice($str, $i, $i);
      $next: string.slice($str, $i + 1, $i + 1);

      @if $curr == "p" and $next == "x" {
        $chars: "";
        $is-paused: false;

        @for $j from $i - 1 through 0 {
          @if $is-paused {
            // sass doesn't support break statement
          } @else {
            $curr: string.slice($str, $j, $j);

            @if is-digit($curr) or $curr == "." or $curr == "-" {
              $chars: $curr + $chars;
            } @else {
              $is-paused: true;
            }
          }
        }

        $tokens: append($tokens, (
          $chars + "px",
          $i - string.length($chars),
        ));
      }
    }

    @return $tokens;
  } @else {
    @error "The argument must be a string.";
  }
}

@function remove-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@function calc-viewport-width($value) {
  @if type-of($value) == "number" and unit($value) == "px" {
    @return remove-unit($value * $one-px * 2) + vw
  } @else {
    @return $value;
  }
}

@mixin scale($property, $values) {
  $attributes: ();
  $mobile-attributes: ();

  @each $value in $values {
    @if type-of($value) == "string" {
      $tokens: tokenization($value);
      $cursor: 1;
      $result: "";
      $expression: $value;

      @each $token in $tokens {
        $raw: nth($token, 1);
        $start-index: nth($token, 2);
        $viewport-width: calc-viewport-width(to-number($raw));

        $part: string.slice($expression, $cursor, $start-index - 1) + $viewport-width;
        $cursor: $start-index + string.length($raw);
        $result: $result + $part;
      }

      $result: $result + string.slice($expression, $cursor, string.length($expression));

      $attributes: append($attributes, $value);
      $mobile-attributes: append($mobile-attributes, string.unquote($result));
    } @else if type-of($value) == "number" {
      $viewport-width: calc-viewport-width($value);
      $attributes: append($attributes, $value);
      $mobile-attributes: append($mobile-attributes, $viewport-width);
    } @else {
      $attributes: append($attributes, $value);
      $mobile-attributes: append($mobile-attributes, $value);
    }
  }

  @if length($attributes) > 0 {
    #{$property}: $attributes;
  }

  @if length($mobile-attributes) > 0 {
    @media (max-width: $mobile-contents-width) {
      #{$property}: $mobile-attributes;
    }
  }
}

@mixin on-mobile {
  @media screen and (max-width: $mobile-contents-width) {
    @content;
  }
}

@mixin appear() {
  &:global(.appear),
  &:not(:global(.appear)) {
    @content;
  }
}

@mixin appear-active() {
  &:global(.appear-active),
  &:global(.appear-done) {
    @content;
  }
}

@mixin enter() {
  &:global(.enter),
  &:not(:global(.enter)) {
    @content;
  }
}

@mixin enter-active() {
  &:global(.enter-active),
  &:global(.enter-done) {
    @content;
  }
}

@mixin exit() {
  &:global(.exit) {
    @content;
  }
}

@mixin exit-active() {
  &:global(.exit-active),
  &:global(.exit-done) {
    @content;
  }
}

.tower {
  @include scale(width, 844px);
  @include scale(height, 989px);
  @include scale(bottom, -400px);
  @include scale(left, 650px);
  position: absolute;

  @include on-mobile {
    @include scale(width, 592px);
    @include scale(height, 693px);
    @include scale(bottom, -325px);
    @include scale(left, -116px);
  }
}


.show {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:nth-child(1) {
    background-image: url(../../resources/images/tower_0.png);
  }

  &:nth-child(2) {
    background-image: url(../../resources/images/tower_1.png);
  }

  &:nth-child(3) {
    background-image: url(../../resources/images/tower_2.png);
  }

  &:nth-child(4) {
    background-image: url(../../resources/images/tower_3.png);
  }

  &:nth-child(5) {
    background-image: url(../../resources/images/tower_4.png);
  }


  &.isSelected {
    opacity: 1;
  }

  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.cat {
  @include scale(width, 123px);
  @include scale(height, 93px);

  @include on-mobile {
    @include scale(width, 86px);
    @include scale(height, 65px);
  }
  
  position: absolute;
  top: 5.55%;
  left: 50%;
  transform: translateX(-63%);
  background-image: url(../../resources/images/cat.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
