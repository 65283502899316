@use "sass:map";
@use "sass:list";
@use "sass:math";
@use "sass:string";

@import "../../resources/styles/easing";

$mobile-contents-width: 720px;

$one-px: math.div(1px, $mobile-contents-width) * 100;

$digit-chars-map: (
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
);

@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    @error "Expected a string, but got #{$value}.";
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';

  @for $i from if($minus, 2, 1) through str-length($value) {
    $char: str-slice($value, $i, $i);

    @if not (index(map-keys($digit-chars-map), $char) or $char == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i))
    }

    @if $char == '.' {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($digit-chars-map, $char);
    } @else {
      $digits: $digits * 10;
      $result: $result + math.div(map-get($digit-chars-map, $char), $digits);
    }
  }

  @return if($minus, -$result, $result);
}

@function to-length($value, $unit) {
  $units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);

  @if not index(map-keys($units), $unit) {
    @error "Unknown unit #{$unit}.";
  }

  @return $value * map-get($units, $unit);
}

@function is-digit($str) {
  @if type-of($str) == "string" {
    @return map.get($digit-chars-map, $str) != null;
  } @else {
    @return false;
  }
}

@function tokenization($str) {
  @if type-of($str) == "string" {
    $tokens: ();
    $cursor: 0;

    @for $i from 1 through string.length($str) {
      $curr: string.slice($str, $i, $i);
      $next: string.slice($str, $i + 1, $i + 1);

      @if $curr == "p" and $next == "x" {
        $chars: "";
        $is-paused: false;

        @for $j from $i - 1 through 0 {
          @if $is-paused {
            // sass doesn't support break statement
          } @else {
            $curr: string.slice($str, $j, $j);

            @if is-digit($curr) or $curr == "." or $curr == "-" {
              $chars: $curr + $chars;
            } @else {
              $is-paused: true;
            }
          }
        }

        $tokens: append($tokens, (
          $chars + "px",
          $i - string.length($chars),
        ));
      }
    }

    @return $tokens;
  } @else {
    @error "The argument must be a string.";
  }
}

@function remove-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@function calc-viewport-width($value) {
  @if type-of($value) == "number" and unit($value) == "px" {
    @return remove-unit($value * $one-px * 2) + vw
  } @else {
    @return $value;
  }
}

@mixin scale($property, $values) {
  $attributes: ();
  $mobile-attributes: ();

  @each $value in $values {
    @if type-of($value) == "string" {
      $tokens: tokenization($value);
      $cursor: 1;
      $result: "";
      $expression: $value;

      @each $token in $tokens {
        $raw: nth($token, 1);
        $start-index: nth($token, 2);
        $viewport-width: calc-viewport-width(to-number($raw));

        $part: string.slice($expression, $cursor, $start-index - 1) + $viewport-width;
        $cursor: $start-index + string.length($raw);
        $result: $result + $part;
      }

      $result: $result + string.slice($expression, $cursor, string.length($expression));

      $attributes: append($attributes, $value);
      $mobile-attributes: append($mobile-attributes, string.unquote($result));
    } @else if type-of($value) == "number" {
      $viewport-width: calc-viewport-width($value);
      $attributes: append($attributes, $value);
      $mobile-attributes: append($mobile-attributes, $viewport-width);
    } @else {
      $attributes: append($attributes, $value);
      $mobile-attributes: append($mobile-attributes, $value);
    }
  }

  @if length($attributes) > 0 {
    #{$property}: $attributes;
  }

  @if length($mobile-attributes) > 0 {
    @media (max-width: $mobile-contents-width) {
      #{$property}: $mobile-attributes;
    }
  }
}

@mixin on-mobile {
  @media screen and (max-width: $mobile-contents-width) {
    @content;
  }
}

@mixin appear() {
  &:global(.appear),
  &:not(:global(.appear)) {
    @content;
  }
}

@mixin appear-active() {
  &:global(.appear-active),
  &:global(.appear-done) {
    @content;
  }
}

@mixin enter() {
  &:global(.enter),
  &:not(:global(.enter)) {
    @content;
  }
}

@mixin enter-active() {
  &:global(.enter-active),
  &:global(.enter-done) {
    @content;
  }
}

@mixin exit() {
  &:global(.exit) {
    @content;
  }
}

@mixin exit-active() {
  &:global(.exit-active),
  &:global(.exit-done) {
    @content;
  }
}

$pi: 3.141592653589793;
$pi2: $pi * 2;

@function x($deg) {
  @return math.cos($deg * math.div($pi, 180));
}

@function y($deg) {
  @return math.sin($deg * math.div($pi, 180));
}

.mobileGnb {
  .icon {
    @include scale(width, 24px);
    @include scale(height, 24px);
    @include scale(top, 15px);
    @include scale(right, 15px);
    position: fixed;
    z-index: 99;

    .divider {
      @include scale(width, 18px);
      @include scale(height, 2px);
      @include scale(border-radius, 100px);
      background: #121517;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      transition: .3s $ease-in-out-quint transform, .3s $ease-in-out-quint opacity;

      &:nth-child(1) {
        @include scale(top, 5px);
      }

      &:nth-child(2) {
        @include scale(top, 11px);
      }

      &:nth-child(3) {
        @include scale(top, 17px);
      }
    }
  }

  &.open {
    .icon {
      .divider {
        &:nth-child(1) {
          @include scale(transform, rotate(45deg)  translate(6px * x(45), 6px * y(45)));
        }

        &:nth-child(2) {
          @include scale(transform, rotate(-45deg));
        }

        &:nth-child(3) {
          @include scale(transform, translateY(5px));
          opacity: 0;
        }
      }
    }
  }

  .gnb {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: 0.3s opacity $ease-out-sin;

    @include enter() {
      opacity: 0;
    }

    @include enter-active() {
      opacity: 1;
    }

    @include scale(padding-top, 94px);

    .gnbLinks {
      @include scale(margin-bottom, 20px);
      display: flex;
      flex-direction: column;

      a {
        @include scale(padding, 16px 20px);
        @include scale(font-size, 24px);
        @include scale(line-height, 36px);
        font-weight: bold;
      }
    }

    .socialLinks {
      display: flex;
      flex-direction: column;

      a {
        @include scale(padding, 8px 20px);
        @include scale(padding-left, 48px);
        @include scale(font-size, 16px);
        @include scale(line-height, 26px);

        &.instagram {
          @include scale(background, url(../../resources/images/instagram_black.svg) no-repeat 20px center);
          @include scale(background-size, 24px);
        }

        &.twitter {
          @include scale(background, url(../../resources/images/twitter_black.svg) no-repeat 20px center);
          @include scale(background-size, 24px);
        }
      }
    }

    .copyright {
      @include scale(font-size, 12px);
      @include scale(line-height, 22px);
      color: #ADB5BD;
      position: absolute;
      @include scale(left, 20px);
      @include scale(bottom, 24px);
    }
  }

  &:not(.open) {
    .gnb {
      pointer-events: none;
    }
  }
}
