@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Poppins-Regular.eot');
  src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Poppins-Regular.woff2') format('woff2'),
  url('../fonts/Poppins-Regular.woff') format('woff'),
  url('../fonts/Poppins-Regular.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Poppins-Medium.eot');
  src: url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Poppins-Medium.woff2') format('woff2'),
  url('../fonts/Poppins-Medium.woff') format('woff'),
  url('../fonts/Poppins-Medium.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Poppins-SemiBold.eot');
  src: url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
  url('../fonts/Poppins-SemiBold.woff') format('woff'),
  url('../fonts/Poppins-SemiBold.ttf') format("truetype");
  font-display: swap;
}
